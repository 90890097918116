import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_ERROR, HIDE_ERROR, SET_LANG, SET_HIGH_CONTRAST} from '../constants/ActionTypes'

function resetState(){
  return {
    initDone: false,
    invalid: false,
    lang: false,
    high_contrast: localStorage.getItem('high_contrast') || false,
    session: {
      token: false,
      username: false
    }
  }
}

function getLang() {
  const lang = navigator.language || 'en-US';
  setLang(lang);
  return lang;
}

function setLang(lang) {
  localStorage.setItem('lang', lang);
}

function setHighContrast(high_contrast){
  localStorage.setItem('high_contrast', high_contrast)
}

function destroySession(){
  localStorage.removeItem('ideaerp_token')
  localStorage.removeItem('ideaerp_username')
}

export default function auth(state = resetState(), action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state,
        lang: getLang(),
        invalid: false,
        session: action.payload
      }
    case LOGOUT_USER:
      destroySession()
      return { ...resetState(),
        initDone: true
      }
    case LOGIN_USER_ERROR:
      return { ...state,
        invalid: true
      }
    case HIDE_ERROR:
      return { ...state,
        invalid: false
      }
    case SET_LANG:
      setLang(action.payload)
      return { ...state,
        lang: action.payload,
        initDone: true
      }
    case SET_HIGH_CONTRAST:
      setHighContrast(action.payload)
      return state
    default:
      return state
  }
}
